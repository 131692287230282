<template>
<div class="flex flex-column flex-auto">
    <div class="p-5">
        <div class="grid">
            <!-- Asset types -->
            <div class="col-12">
                <div class="surface-card shadow-2 border-round p-3 mb-3">
                    <div class="grid">
                        <div class="col-6">
                            <Button :class="showActive ? 'w-full' : 'w-full p-button-outlined'" label="Active Assets" :icon="showActive ? 'pi pi-eye' : 'pi pi-eye-slash'" @click="showActive=!showActive" />
                        </div>
                        <div class="col-6">
                            <Button :class="showProspects ? 'w-full' : 'w-full p-button-outlined'" label="All Prospect" :icon="showProspects ? 'pi pi-eye' : 'pi pi-eye-slash'" @click="showProspects=!showProspects" />
                        </div>
                        <div class="col-12">
                            <Button :class="showLeaked ? 'w-full' : 'w-full p-button-outlined'" :label="!showLeaked ? 'Not Yet Leaked Assets' : 'Leaked Assets'" :icon="showLeaked ? 'pi pi-eye' : 'pi pi-eye-slash'" @click="showLeaked=!showLeaked" />
                        </div>
                    </div>
                </div>
            </div>

            <!-- Insert new infohash -->
            <div class="col-12">
                <div class="surface-card shadow-2 border-round p-3 mb-3">
                    <div class="grid">
                        <div class="col-12">
                            <h3>Insert New Infohash</h3>
                        </div>
                        <div class="col-4">
                            <InputNumber class="w-full" v-model="assetId" placeholder="Asset Id" />
                        </div>
                        <div class="col-8">
                            <InputText class="w-full" v-model="newInfohash" placeholder="Infohash" />
                        </div>
                        <div class="col-12">
                            <Button class="w-full" label="Insert" @click="insertInfohash" :disabled="assetId===0 && newInfohash===''"/>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Content -->
            <div class="col-12">
                <div class="surface-card shadow-2 border-round p-3">
                    <DataTable :value="tableData" :loading="loading" class="w-full" v-model:expandedRows="expandedRows" :rowClass="infohashRowClass" striped-rows>
                        <Column expander style="width: 5rem" />
                        <Column field="assetid" header="Asset Id" ></Column>
                        <Column field="client" header="Client Name" ></Column>
                        <Column field="assetname" header="Asset Name" ></Column>
                        <Column field="firstdate" header="First Leaked" ></Column>
                        <Column field="totalhashes" header="Number of Hashes" ></Column>

                        <template #expansion="slotProps">
                            <!-- Torrent Trackers -->
                            <div class="grid">
                                <a class="col-6" target="_blank" :href="'https://1337x.to/search/' + slotProps.data.assetname.toLowerCase().replaceAll(' ', '+') + '/1/'">
                                    <Button class="w-full" label="1337x"/>
                                </a>

                                <a class="col-6" target="_blank" :href="'https://thepiratebay.party/search/' + slotProps.data.assetname.toLowerCase().replaceAll(' ', '+') + '/1/'">
                                    <Button class="w-full" label="PirateBay"/>
                                </a>
                            </div>


                            <div class="p-3">
                                <h3>Infohashes for {{ slotProps.data.assetname }}</h3>
                                <DataTable :value="slotProps.data.hashes">
                                    <Column field="infohashid" header="Infohash Id"></Column>
                                    <Column field="hash" header="Infohash"></Column>
                                    <Column field="entrydate" header="Date Found"></Column>
                                    <Column field="filename" header="Filename"></Column>
                                    <Column field="filesize" header="Size"></Column>
                                </DataTable>
                            </div>
                        </template>
                    </DataTable>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
export default {
    name: "TorrentHashes",

    data() {
        return {
            loading: false,
            info: [],
            tableData: [],
            expandedRows: [],

            showActive: true,
            showProspects: false,
            showLeaked: false,

            assetId: 0,
            newInfohash: '',
        }
    },

    mounted() {
        this.getInfohashes();
    },

    methods: {
        getInfohashes() {
            this.tableData = [];
            if (this.info.length === 0) {
                this.loading = true;
                this.$factory.asset.getAllInfohashInfo().then((response) => {
                    // link hashes to info.hashes based on infohashid
                    this.info = response.info
                    this.info.forEach((info) => {
                        info.hashes = response.hashes.filter((hash) => {
                            return hash.imdbid === info.imdbid;
                        });
                    });

                    // loop through hashes and add to info.hashes
                    // response.hashes.forEach((hash) => {
                    //     // find infohashid in info
                    //     let info = this.info.find((info) => {
                    //         return info.imdbid === hash.imdbid;
                    //     });
                    //
                    //     // add hash to infohashes
                    //     info.hashes.push(hash);
                    // });
                    console.debug(this.info);
                    this.showStatus();
                }).catch((error) => {
                    this.$emitter.emit('bad-toast', 'Failed to get infohashes');
                    console.error(error);
                }).finally(() => {
                    this.loading = false;
                });
            } else {
                this.showStatus();
            }
        },

        showStatus() {
            // Get asset data from store and compare against infohash data fetched to show correct assets
            console.debug(this.info)
            this.info.forEach((asset) => {
                // Show nothing if no filters are selected
                if (!this.showActive && !this.showProspects) {
                    return;
                }

                let assetData = this.$store.getters.getAllAssetData.filter((assetData) => {
                    return assetData.id === asset.assetid;
                });

                assetData = assetData[0];

                // Remove prospects if not selected
                if (assetData.type.includes('Prospect') && !this.showProspects) {
                    return;
                }

                // Remove if not leaked and selected
                // Remove if leaked and not selected
                if (asset.hashes.length === 0 && this.showLeaked) {
                    return;
                } else if (asset.totalhashes !== 0 && !this.showLeaked) {
                    return;
                }

                // Add asset to tableData if active and showActive is true
                // Add asset to tableData if prospect and showProspects is true
                if (assetData.active && this.showActive) {
                    this.tableData.push(asset)
                } else if (assetData.type.includes('Prospect') === this.showProspects) {
                    this.tableData.push(asset)
                }
            })
        },

        infohashRowClass(data) {
            if (data.firstdate === 'unknown') {
                return 'bg-gray-200';
            }
        },

        insertInfohash() {
            this.$factory.asset.insertInfohash(this.assetId, this.newInfohash).then(() => {
                this.$emitter.emit('good-toast', 'Infohash inserted');
                this.newInfohash = '';
                this.getInfohashes();
            }).catch((error) => {
                this.$emitter.emit('bad-toast', 'Failed to insert infohash');
                console.error(error);
            });
        }
    },

    watch: {
        showActive: function() {
            this.getInfohashes();
        },
        showProspects: function() {
            this.getInfohashes();
        },
        showLeaked: function() {
            this.getInfohashes();
        },
    }
}
</script>

<style scoped>

</style>
